// @noflow
import type { Language } from '@/packs/localisation'
// Static content
import EstimatedDailyPlanPrices from '@/static_content/EstimatedDailyPlanPrices'
import { ageOptions } from '@/static_content/dropdownOptions'
import Cookies from 'js-cookie'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DiscountBasis } from '@/utils/butternutbox/discountCodes'
import * as Sentry from '@/utils/sentry'
import { UserType, wizardCtaLink } from '@/utils/wizardCtaLink'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Plan from './components/Plan'
import segmentTrack from '@/components/analytics/Analytics'
import withApollo from '@/components/apollo/withApollo'
import type { BreedSelectorQuery_breeds as Breed } from '@/components/elements/molecules/BreedAutocomplete/queries/__generated__/BreedSelectorQuery'
import BreedAutocomplete from '@/components/shared/elements/BreedSelector/components/BreedAutocomplete/BreedAutocomplete'
import Image from '@/components/shared/elements/Image/Image'
import AlternativeRoughCostCalculator from '@/components/shared/elements/RoughCostCalculator/AlternativeRoughCalculator'
import { ageToAgeCategory } from '@/components/shared/elements/RoughCostCalculator/helpers/roughCostCalculatorHelpers'
import Select from '@/components/shared/elements/Select/Select'
import type { Option } from '@/components/shared/elements/Select/Select'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

import { BreedSelectorVariant } from '../BreedSelector/BreedSelector'

type Props = {
  preferredLanguage: Language
  currentUserType?: UserType
  screenIdentifier: string
  discountValue: number
  discountBasis: DiscountBasis
  shippingCountryCode: CountryCode
  showAlternativePtHero?: boolean
  trustpilotBusinessUnitId?: string
}

const RoughCostCalculator = ({
  preferredLanguage,
  currentUserType,
  screenIdentifier,
  discountValue,
  discountBasis,
  shippingCountryCode,
  trustpilotBusinessUnitId,
  showAlternativePtHero = false
}: Props): JSX.Element | null => {
  const namespace = 'homepage'
  const copyContext = 'rough_cost_calculator'

  const { windowWidth } = useWindowSize()
  const { t } = useTranslation(namespace)
  const [selectedBreed, setSelectedBreed] = useState<string>()
  const isDesktop = windowWidth > BREAKPOINTS.lg

  // Retrieve any saved dog details from localStorage if user has interacted before
  const roughCostCalculatorLocalStorage = localStorage.getItem(
    'rough_cost_calculator'
  )
  const initialState = {
    age: 2,
    weight: 'medium_2',
    interactedWithAge: false
  }
  const parsedState = roughCostCalculatorLocalStorage
    ? JSON.parse(roughCostCalculatorLocalStorage)
    : initialState
  const [dogDetails, setDogDetails] = useState(parsedState)

  const handleAgeChange = useCallback(
    (option: Option) => {
      // We want to set interactedWithAge explicitly as true so that we don't end up using the
      // defaulted value of 2 years old in the Signup Wizard if this isn't a value picked by the user
      setDogDetails({
        ...dogDetails,
        age: parseInt(option.value.toString()),
        interactedWithAge: true
      })
    },
    [dogDetails]
  )
  const handleWeightChange = useCallback(
    (option: Option) => {
      setDogDetails({ ...dogDetails, weight: option.value })
    },
    [dogDetails]
  )

  const handleCTAClick = useCallback(() => {
    const eventName = 'Component Clicked'
    const properties = {
      screen_identifier: screenIdentifier,
      component_identifier: 'rough_cost_calculator_cta',
      breed: selectedBreed
    }
    segmentTrack(eventName, properties)
    window.location.href = currentUserType
      ? wizardCtaLink(currentUserType).url
      : '/wizard/new'
  }, [screenIdentifier, selectedBreed, currentUserType])

  const onBreedSelectionCallback = useCallback((breed: Breed) => {
    setSelectedBreed(breed?.key)
    // Set Breed ID cookie to be accessed by the Wizard to allow
    // for pre-populated breed selection
    Cookies.set(
      'breedSelectorBreedId',
      JSON.stringify({
        id: parseInt(breed.id),
        key: breed.key,
        name: breed.name
      })
    )
  }, [])

  useEffect(() => {
    localStorage.setItem('rough_cost_calculator', JSON.stringify(dogDetails))
  }, [dogDetails])

  const getDiscountedPrice = useCallback(
    (price: number, discountValue: number, discountBasis: DiscountBasis) => {
      if (discountValue && discountBasis === 'percentage') {
        return price - (price * discountValue) / 100
      }
    },
    []
  )

  const dogAgeCategory = ageToAgeCategory(dogDetails.age)
  const weightOptions = uniqBy(
    EstimatedDailyPlanPrices,
    (estimate) => estimate.size
  )
  const dailyPlanPrices = EstimatedDailyPlanPrices.find(
    ({ ageCategory, size }) =>
      ageCategory === dogAgeCategory && size === dogDetails.weight
  )
  if (!dailyPlanPrices) {
    Sentry.captureException(
      'dailyPlanPrices not found for Rough Cost Calculator'
    )
    return null
  }

  const mixedPlanDailyPrice = dailyPlanPrices[shippingCountryCode].mix
  const allPlanDailyPrice = dailyPlanPrices[shippingCountryCode].all

  const baseClass = 'rough-cost-calculator__cost-calculator'

  const formattedAgeOptions = ageOptions.map((option, index): Option => {
    return {
      id: index.toString(),
      value: option.value,
      label: t(`${copyContext}.age.${option.text}`)
    }
  })

  const formattedWeightOptions = weightOptions.map((option, index): Option => {
    return {
      id: index.toString(),
      value: option.size,
      label: t(`${copyContext}.weight.${option.size}`)
    }
  })

  const allPlanDiscountedPrice =
    (discountValue &&
      discountBasis &&
      getDiscountedPrice(
        allPlanDailyPrice,
        discountValue,
        discountBasis as DiscountBasis
      )) ||
    0
  const mixedPlanDiscountedPrice =
    discountValue && discountBasis
      ? getDiscountedPrice(
          mixedPlanDailyPrice,
          discountValue,
          discountBasis as DiscountBasis
        )
      : 0
  const formattedAgeOption = formattedAgeOptions.find(
    (option) => option.value === initialState.age
  )
  const formattedWeightOption = formattedWeightOptions.find(
    (option) => option.value === initialState.weight
  )

  if (showAlternativePtHero) {
    return (
      <AlternativeRoughCostCalculator
        preferredLanguage={preferredLanguage}
        shippingCountryCode={shippingCountryCode}
        trustpilotBusinessUnitId={trustpilotBusinessUnitId}
        baseClass={baseClass}
        formattedAgeOptions={formattedAgeOptions}
        formattedAgeOption={formattedAgeOption}
        formattedWeightOptions={formattedWeightOptions}
        formattedWeightOption={formattedWeightOption}
        discountedPrice={allPlanDiscountedPrice}
        allPlanDailyPrice={allPlanDailyPrice}
        handleCTAClick={handleCTAClick}
        handleWeightChange={handleWeightChange}
        handleAgeChange={handleAgeChange}
        onBreedSelectionCallback={onBreedSelectionCallback}
      />
    )
  }

  return (
    <div className={`${baseClass}`}>
      <div className={`${baseClass}__text-wrapper`}>
        <h3 className={`${!isDesktop ? 'display-36' : 'display-45'} no-scale`}>
          {t(`${copyContext}.title`)}
        </h3>
        <div className={`${baseClass}__card`}>
          <p className={`text-regular-20 no-scale ${baseClass}__text`}>
            {t(`${copyContext}.description`)}
          </p>
          <div className={`${baseClass}__breed-row`}>
            <p className={`text-regular-18 no-scale breed-label`}>
              {t(`${copyContext}.breed_label`)}
            </p>
            <div className={`${baseClass}__breed-autocomplete`}>
              <BreedAutocomplete
                loadingPlaceholder={t(`${copyContext}.breed_loading`)}
                placeholder={t(`${copyContext}.breed_placeholder`)}
                events={{
                  onBreedSelection: onBreedSelectionCallback,
                  onListOpen: () => null,
                  onFocus: () => null,
                  onInputEmpty: () => null
                }}
                variant={BreedSelectorVariant.Generic}
              />
            </div>
          </div>
          <div className={`${baseClass}__dropdown-row`}>
            <div className={`${baseClass}__dropdown-wrapper`}>
              <Select
                onChange={handleAgeChange}
                label={t(`${copyContext}.age_label`)}
                options={formattedAgeOptions}
                initialValue={formattedAgeOption}
              />
            </div>
            <div className={`${baseClass}__dropdown-wrapper`}>
              <Select
                onChange={handleWeightChange}
                label={t(`${copyContext}.weight_label`)}
                options={formattedWeightOptions}
                initialValue={formattedWeightOption}
              />
            </div>
          </div>
          <div className={`${baseClass}__plan-row`}>
            <Plan
              description={t(`${copyContext}.mix_plan`)}
              discountedPrice={mixedPlanDiscountedPrice}
              strikethroughPrice={mixedPlanDailyPrice}
              planType="mix"
              copyContext={copyContext}
              namespace={namespace}
              preferredLanguage={preferredLanguage}
              shippingCountryCode={shippingCountryCode}
            />
            <Plan
              description={t(`${copyContext}.full_butternut`)}
              discountedPrice={allPlanDiscountedPrice}
              strikethroughPrice={allPlanDailyPrice}
              planType="all"
              copyContext={copyContext}
              namespace={namespace}
              preferredLanguage={preferredLanguage}
              shippingCountryCode={shippingCountryCode}
            />
          </div>
          <div className={`${baseClass}__cta`}>
            <button
              className="btn primary"
              type="button"
              onClick={handleCTAClick}
            >
              {t(`${copyContext}.build_your_box`)}
            </button>
          </div>
        </div>
      </div>
      <div className={`${baseClass}__image-wrapper`}>
        <Image
          alt={t(`${copyContext}.image_alt`)}
          slug="charlie-standing-in-front-of-open-fridge"
          image={{
            width: 640,
            height: 857,
            resizeMode: 'resize_to_fit'
          }}
          className={`${baseClass}__image-wrapper__image`}
        />
      </div>
    </div>
  )
}

export { Props }
export default withApollo(RoughCostCalculator)
