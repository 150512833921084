import * as Sentry from '@sentry/browser'
import { Extras, SeverityLevel } from '@sentry/types'

enum Product {
  Account = 'Account',
  Checkout = 'Checkout',
  HomePage = 'HomePage',
  PaidTraffic = 'PaidTraffic',
  PlansFlow = 'PlansFlow',
  Wizard = 'Wizard',
  Influencer = 'Influencer',
  Ambassador = 'Ambassador'
}

enum Team {
  Retention = 'retention',
  CoreEx = 'core-ex'
}

type Options = {
  extra?: Extras | unknown
  tags?: {
    product?: Product
    team?: Team
  }
}

type CaptureMessageOptions = { logLevel: SeverityLevel } & Options

/**
 * Calls Sentry captureMessage event with configurable message, logging level and additional data
 *
 * @name captureMessage
 *
 * @function
 *
 * @param { string } message - Event that will be displayed as Sentry issue title
 * @param { Object } options - Options to pass to Sentry.captureEvent
 * @param { Object } options.extra - Additional object containing any error data
 * @param { string } options.logLevel - Log level in Sentry, if this is `error` and NODE_EVN === `development` this will change to `warning`
 * @param { Object } options.tags - Object containing custom tags to be added to the Sentry event
 *
 * @returns { void }
 */
export const captureMessage = (
  message: string,
  { extra, logLevel = 'warning', tags }: CaptureMessageOptions
): void => {
  Sentry.withScope((scope) => {
    extra && scope.setExtras(extra as Extras)

    const level =
      process.env.NODE_ENV === 'development' && logLevel === 'error'
        ? 'warning'
        : logLevel

    scope.setLevel(level)

    if (tags) scope.setTags(tags)

    Sentry.captureMessage(message)
  })
}

/**
 * Calls Sentry captureException event with configurable message, logging level and additional data
 *
 * @name captureException
 *
 * @function
 *
 * @param { string } message - Event that will be displayed as Sentry issue title
 * @param { Object } options - Options to pass to Sentry.captureEvent
 * @param { Object } options.extra - Additional object containing any error data
 *
 * @returns { void }
 */
export const captureException = (message: string, options?: Options): void =>
  captureMessage(message, { ...options, logLevel: 'error' })

export { Product, Team }
