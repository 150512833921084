// @noflow
import Cookies from 'js-cookie'
import React from 'react'

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (element: HTMLElement, arg: boolean) => void
    }
  }
}

enum TemplateId {
  Default = '5419b6ffb0d04a076446a9af',
  Slim = '5419b732fbfb950b10de65e5'
}

enum Variant {
  Default = 'default',
  Slim = 'slim'
}

enum Theme {
  Light = 'light',
  Dark = 'dark'
}

type Props = {
  businessUnitId: string
  width: string
  height: string
  variant: Variant
  theme: Theme
}

const TrustpilotWidget = ({
  businessUnitId,
  width,
  height,
  variant,
  theme
}: Props): JSX.Element => {
  const trustBoxRef = React.useRef<HTMLDivElement>(null)
  const language = Cookies.get('user_language')
  const shippingCountryCode = Cookies.get('user_country_code')
  const locale =
    language && shippingCountryCode
      ? `${language}-${shippingCountryCode}`
      : 'en-GB'

  React.useEffect((): void => {
    if (window.Trustpilot && trustBoxRef.current) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true)
    }
  }, [])

  return (
    <section className="trustpilot-section">
      <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-businessunit-id={businessUnitId}
        data-locale={locale}
        data-style-height={height}
        data-style-width={width}
        data-template-id={
          variant === Variant.Slim ? TemplateId.Slim : TemplateId.Default
        }
        data-theme={theme}
      />
    </section>
  )
}

export { Props, Variant, Theme }
export default TrustpilotWidget
