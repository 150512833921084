// @noflow
import type { Language } from '@/packs/localisation'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import BREAKPOINTS from '@/constants/Breakpoints'

import useWindowSize from '@/hooks/useWindowSize'

import Plan from './components/Plan'
import TrustpilotWidget, {
  Theme,
  Variant
} from '@/components/elements/atoms/TrustpilotWidget/TrustpilotWidget'
import type { BreedSelectorQuery_breeds as Breed } from '@/components/elements/molecules/BreedAutocomplete/queries/__generated__/BreedSelectorQuery'
import BreedAutocomplete from '@/components/shared/elements/BreedSelector/components/BreedAutocomplete/BreedAutocomplete'
import Select from '@/components/shared/elements/Select/Select'
import type { Option } from '@/components/shared/elements/Select/Select'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

import { BreedSelectorVariant } from '../BreedSelector/BreedSelector'

type Props = {
  preferredLanguage: Language
  shippingCountryCode: CountryCode
  trustpilotBusinessUnitId?: string
  baseClass: string
  formattedAgeOptions: Option[]
  formattedAgeOption?: Option
  formattedWeightOptions: Option[]
  formattedWeightOption?: Option
  allPlanDailyPrice: number
  discountedPrice: number
  handleCTAClick: () => void
  handleWeightChange: (option: Option) => void
  handleAgeChange: (option: Option) => void
  onBreedSelectionCallback: (breed: Breed) => void
}

const AlternativeRoughCostCalculator = ({
  preferredLanguage,
  shippingCountryCode,
  trustpilotBusinessUnitId,
  baseClass,
  formattedAgeOptions,
  formattedAgeOption,
  formattedWeightOptions,
  formattedWeightOption,
  allPlanDailyPrice,
  handleCTAClick,
  discountedPrice,
  handleWeightChange,
  handleAgeChange,
  onBreedSelectionCallback
}: Props): JSX.Element | null => {
  const namespace = 'homepage'
  const copyContext = 'rough_cost_calculator'

  const { windowWidth } = useWindowSize()
  const { t } = useTranslation(namespace)
  const roughCostCalculatorRef = useRef<HTMLDivElement>(null)
  const isDesktop = windowWidth > BREAKPOINTS.lg

  const onFocusCallback = useCallback(() => {
    if (!isDesktop && roughCostCalculatorRef.current) {
      const containerDistanceFromTop =
        roughCostCalculatorRef.current.getBoundingClientRect().top

      const y = containerDistanceFromTop + window.pageYOffset - 60

      // On iOS, when a user focuses on an input, the window is automatically
      // scrolled so the input element is placed above the keyboard. By setting a
      // very small timeout, we can ensure that our custom scroll position does not
      // scroll too far which was observed previously.
      setTimeout(() => window.scrollTo({ top: y, behavior: 'smooth' }), 100)
    }
  }, [isDesktop])

  return (
    <div className={`${baseClass}__alternative`}>
      <div className={`${baseClass}__card`}>
        <h3 className={`${isDesktop ? 'display-24' : 'display-20'} no-scale`}>
          {t(`${copyContext}.alternative_title`)}
        </h3>
        <div className={`${baseClass}__breed-row`}>
          <p className={`text-regular-18 no-scale breed-label`}>
            {t(`${copyContext}.breed_label`)}
          </p>
          <div
            className={`${baseClass}__breed-autocomplete`}
            ref={roughCostCalculatorRef}
          >
            <BreedAutocomplete
              loadingPlaceholder={t(`${copyContext}.breed_loading`)}
              placeholder={t(`${copyContext}.breed_placeholder`)}
              events={{
                onBreedSelection: onBreedSelectionCallback,
                onListOpen: () => null,
                onFocus: onFocusCallback,
                onInputEmpty: () => null
              }}
              variant={BreedSelectorVariant.Generic}
            />
          </div>
        </div>
        <div className={`${baseClass}__dropdown-row`}>
          <div className={`${baseClass}__dropdown-wrapper`}>
            <Select
              onChange={handleAgeChange}
              label={t(`${copyContext}.age_label`)}
              options={formattedAgeOptions}
              initialValue={formattedAgeOption}
            />
          </div>
          <div className={`${baseClass}__dropdown-wrapper`}>
            <Select
              onChange={handleWeightChange}
              label={t(`${copyContext}.weight_label`)}
              options={formattedWeightOptions}
              initialValue={formattedWeightOption}
            />
          </div>
        </div>
        <div className={`${baseClass}__plan-row-alternative`}>
          <Plan
            description={t(`${copyContext}.fresh_butternut`)}
            discountedPrice={discountedPrice}
            strikethroughPrice={allPlanDailyPrice}
            planType="bowl"
            copyContext={copyContext}
            namespace={namespace}
            preferredLanguage={preferredLanguage}
            shippingCountryCode={shippingCountryCode}
          />
        </div>
        <div className={`${baseClass}__cta`}>
          <button
            className="btn primary"
            type="button"
            onClick={handleCTAClick}
          >
            {t(`${copyContext}.build_your_box`)}
          </button>
        </div>
        {trustpilotBusinessUnitId && (
          <TrustpilotWidget
            businessUnitId={trustpilotBusinessUnitId}
            variant={Variant.Default}
            theme={Theme.Light}
            height="80"
            width="100%"
          />
        )}
      </div>
    </div>
  )
}

export default AlternativeRoughCostCalculator
