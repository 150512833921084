// @noflow
import type { Language } from '@/packs/localisation'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { countryCodeToLocaleCurrency } from '@/utils/countryCodeHelper'
import { formatCurrencyWithDecimal } from '@/utils/currency'

import Image from '@/components/shared/elements/Image/Image'

import type { Code as CountryCode } from '@/shared_types/rails_models/shipping_countries'

type Props = {
  description: string
  discountedPrice?: number
  strikethroughPrice: number
  planType: 'mix' | 'all' | 'bowl'
  copyContext: string
  namespace: string
  preferredLanguage: Language
  shippingCountryCode: CountryCode
}

const Plan = ({
  description,
  discountedPrice = 0,
  strikethroughPrice,
  planType,
  copyContext,
  namespace,
  preferredLanguage,
  shippingCountryCode
}: Props): JSX.Element => {
  const baseClass = 'rough-cost-calculator__plan'
  const { t } = useTranslation(namespace)

  const { locale, currency } = countryCodeToLocaleCurrency(
    shippingCountryCode,
    preferredLanguage
  )
  const imageAlt = () => {
    switch (planType) {
      case 'bowl':
        return t(`${copyContext}.bowl_butternut_img_alt`)
      case 'mix':
        return t(`${copyContext}.mix_butternut_img_alt`)
      default:
        return t(`${copyContext}.full_butternut_img_alt`)
    }
  }
  const imageSlug = () => {
    switch (planType) {
      case 'bowl':
        return 'duck-and-chicken-bowl'
      case 'mix':
        return 'most-butternut-with-pink-background'
      default:
        return 'all-butternut-with-pink-background'
    }
  }

  return (
    <div className={`${baseClass}`}>
      <div className={`${baseClass}__image-wrapper`}>
        <Image
          alt={imageAlt()}
          slug={imageSlug()}
          image={{
            width: 80,
            height: 80,
            resizeMode: 'resize_to_fill',
            retina: true
          }}
          className={`${baseClass}__image-wrapper__image`}
        />
      </div>
      <div className={`${baseClass}__text-wrapper`}>
        <p className="text-regular-16 no-scale">{description}</p>
        <p>
          <span
            className={`display-24 no-scale ${
              discountedPrice > 0 ? baseClass + '__discounted-price' : ''
            }`}
          >
            {formatCurrencyWithDecimal(
              discountedPrice > 0 ? discountedPrice : strikethroughPrice,
              {
                locale,
                currency
              }
            )}
          </span>
          <span className="text-regular-16 no-scale">
            {t(`${copyContext}.per_day`)}
          </span>
        </p>
        {discountedPrice > 0 && (
          <p className="text-regular-14 no-scale strikethrough">
            <span>
              {formatCurrencyWithDecimal(strikethroughPrice, {
                locale,
                currency
              })}
            </span>
            {t(`${copyContext}.per_day`)}
          </p>
        )}
      </div>
    </div>
  )
}

export type { Props }
export default Plan
