/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.


//==============================================================
// START Enums and Input Objects
//==============================================================


export enum Account {
  free = "free",
  paid = "paid",
  trial = "trial",
}

export enum Activity {
  cuddling = "cuddling",
  doing_tricks = "doing_tricks",
  making_friends = "making_friends",
  walking = "walking",
}

export enum Affiliate {
  ambassador = "ambassador",
  influencer = "influencer",
  not_affiliate = "not_affiliate",
}

export enum AgeClassificationByUserWhenCreated {
  classified_as_adult_by_user_when_created = "classified_as_adult_by_user_when_created",
  classified_as_puppy_by_user_when_created = "classified_as_puppy_by_user_when_created",
  classified_as_senior_by_user_when_created = "classified_as_senior_by_user_when_created",
  unclassified_when_created = "unclassified_when_created",
}

export enum ApplicableDiscountPartDiscount {
  every_nth_box = "every_nth_box",
  n_boxes = "n_boxes",
  nth_box = "nth_box",
  rollover = "rollover",
  single_use = "single_use",
}

export enum ApplicableDiscountPartDiscountBasis {
  percentage = "percentage",
  total = "total",
}

export enum AppointmentEnum {
  ten_minute = "ten_minute",
  thirty_minute = "thirty_minute",
}

export enum AppointmentStatus {
  cancelled = "cancelled",
  confirmed = "confirmed",
  pending = "pending",
  pending_cancellation = "pending_cancellation",
}

export enum AuthenticationStrategy {
  admin = "admin",
  token = "token",
  user = "user",
}

export enum Availability {
  archived = "archived",
  available = "available",
  unavailable = "unavailable",
}

export enum BodyShape {
  chubby = "chubby",
  just_right = "just_right",
  skinny = "skinny",
}

export enum BoxKind {
  all_bb = "all_bb",
  mix_bb = "mix_bb",
}

export enum Code {
  BE = "BE",
  DE = "DE",
  GB = "GB",
  IE = "IE",
  NI = "NI",
  NL = "NL",
  PL = "PL",
}

export enum Currency {
  EUR = "EUR",
  GBP = "GBP",
  PLN = "PLN",
}

export enum CurrentFood {
  dry_food = "dry_food",
  home_food = "home_food",
  raw_food = "raw_food",
  wet_food = "wet_food",
}

export enum CustomerIssueEntryFieldData {
  association = "association",
  boolean = "boolean",
  integer = "integer",
  multifield = "multifield",
  string = "string",
}

export enum CustomerIssueEntryFieldSelectionFormat {
  multiple = "multiple",
  not_applicable = "not_applicable",
  single = "single",
}

export enum CustomerIssueEntryFieldTypesAssociationAssociationType {
  Product = "Product",
}

export enum CustomerIssueEntryFieldTypesAssociationDisplayType {
  dropdown = "dropdown",
  multiselect = "multiselect",
}

export enum CustomerIssueEntryFieldTypesBooleanDisplayType {
  confirmation_box = "confirmation_box",
}

export enum CustomerIssueEntryFieldTypesIntegerDisplayType {
  numerical_input = "numerical_input",
  stepper = "stepper",
}

export enum CustomerIssueEntryFieldTypesMultifieldDisplayType {
  damaged_product = "damaged_product",
  grouped_list = "grouped_list",
  multi_product_count = "multi_product_count",
  product_count = "product_count",
  unsatisfactory_items = "unsatisfactory_items",
}

export enum CustomerIssueEntryFieldTypesStringDisplayType {
  checkbox = "checkbox",
  dropdown = "dropdown",
  freetext = "freetext",
}

export enum CustomerIssueManagementInputFieldAssociationAssociationType {
  Product = "Product",
}

export enum CustomerIssueManagementInputFieldAssociationDisplayType {
  dropdown = "dropdown",
  multiselect = "multiselect",
}

export enum CustomerIssueManagementInputFieldBooleanDisplayType {
  confirmation_box = "confirmation_box",
}

export enum CustomerIssueManagementInputFieldDataType {
  association = "association",
  boolean = "boolean",
  integer = "integer",
  multifield = "multifield",
  string = "string",
}

export enum CustomerIssueManagementInputFieldIntegerDisplayType {
  numerical_input = "numerical_input",
  stepper = "stepper",
}

export enum CustomerIssueManagementInputFieldMultifieldDisplayType {
  damaged_product = "damaged_product",
  grouped_list = "grouped_list",
  multi_product_count = "multi_product_count",
  product_count = "product_count",
  unsatisfactory_items = "unsatisfactory_items",
}

export enum CustomerIssueManagementInputFieldSelectionFormat {
  multiple = "multiple",
  not_applicable = "not_applicable",
  single = "single",
}

export enum CustomerIssueManagementInputFieldStringDisplayType {
  checkbox = "checkbox",
  dropdown = "dropdown",
  freetext = "freetext",
}

export enum CustomerIssueManagementReportable {
  Box = "Box",
  NonCore_Order = "NonCore_Order",
}

export enum CustomerIssueManagementReporterable {
  AdminUser = "AdminUser",
  User = "User",
}

export enum CustomerIssueManagementResolutionResolutionType {
  apply_discount = "apply_discount",
  help_centre_article = "help_centre_article",
  replace_box = "replace_box",
  track_order = "track_order",
}

export enum CustomerIssueResolutionDetailsTypeField {
  apply_discount = "apply_discount",
  order_tracking_number = "order_tracking_number",
  replace_box = "replace_box",
  replacement_box_details = "replacement_box_details",
  track_order = "track_order",
}

export enum CustomerIssueResolutionKey {
  apply_discount = "apply_discount",
  replace_box = "replace_box",
  replacement_box = "replacement_box",
  track_order = "track_order",
}

export enum CustomerIssueResolutionResolution {
  invariable = "invariable",
  variable = "variable",
}

export enum Descriptor {
  future = "future",
  next = "next",
  past = "past",
}

export enum DiscountCodePartDiscount {
  every_nth_box = "every_nth_box",
  n_boxes = "n_boxes",
  nth_box = "nth_box",
  single_use = "single_use",
}

export enum DiscountCodePartDiscountBasis {
  percentage = "percentage",
  total = "total",
}

export enum Eater {
  eats_anything = "eats_anything",
  fussy_eater = "fussy_eater",
  good_eater = "good_eater",
  picky_eater = "picky_eater",
}

export enum Exercise {
  hyper = "hyper",
  low = "low",
  normal = "normal",
}

export enum FavouriteGame {
  ball_chaser = "ball_chaser",
  just_hanging_out = "just_hanging_out",
  stick_fetcher = "stick_fetcher",
  toy_squeaker = "toy_squeaker",
  tug_of_war_master = "tug_of_war_master",
  unknown_favourite_game = "unknown_favourite_game",
}

export enum FeedingGuidelinesUsed {
  adult_only_feeding_guidelines = "adult_only_feeding_guidelines",
  combined_feeding_guidelines = "combined_feeding_guidelines",
  legacy_feeding_guidelines = "legacy_feeding_guidelines",
  october2020_feeding_guidelines = "october2020_feeding_guidelines",
  snack_feeding_guidelines = "snack_feeding_guidelines",
}

export enum Food {
  dry_food = "dry_food",
  home_food = "home_food",
  raw_food = "raw_food",
  wet_food = "wet_food",
}

export enum FooterColumn {
  column_1 = "column_1",
  column_2 = "column_2",
  column_3 = "column_3",
  social_icons = "social_icons",
  sub_footer = "sub_footer",
}

export enum Frequency {
  one_off = "one_off",
  recurring = "recurring",
}

export enum Gender {
  female = "female",
  male = "male",
}

export enum Group {
  admin = "admin",
  default = "default",
  deprecated = "deprecated",
  more_food = "more_food",
}

export enum InputPaymentMethod {
  billing_agreement = "billing_agreement",
  credit_card = "credit_card",
  direct_debit = "direct_debit",
  merchant_initiated_payment = "merchant_initiated_payment",
  sepa_direct_debit = "sepa_direct_debit",
}

export enum IntentType {
  payment_intent = "payment_intent",
  setup_intent = "setup_intent",
}

export enum InvoiceStatus {
  disputed = "disputed",
  forgiven = "forgiven",
  free = "free",
  paid = "paid",
  processing = "processing",
  unpaid = "unpaid",
}

export enum Key {
  affenpinscher = "affenpinscher",
  affenpoo_affenpinscher_poodle_mix = "affenpoo_affenpinscher_poodle_mix",
  afghan_hound = "afghan_hound",
  aidi = "aidi",
  airedale_terrier = "airedale_terrier",
  airedoodle_airedale_terrier_poodle_mix = "airedoodle_airedale_terrier_poodle_mix",
  akbash = "akbash",
  akita = "akita",
  alano_espanol = "alano_espanol",
  alapaha_blue_blood_bulldog = "alapaha_blue_blood_bulldog",
  alaskan_klee_kai = "alaskan_klee_kai",
  alaskan_malamute = "alaskan_malamute",
  alaskan_malamute_x_dutch_herder = "alaskan_malamute_x_dutch_herder",
  alaskan_malamute_x_northern_innuit = "alaskan_malamute_x_northern_innuit",
  american_akita = "american_akita",
  american_bulldog = "american_bulldog",
  american_bully = "american_bully",
  american_cocker_spaniel = "american_cocker_spaniel",
  american_cockerpoo = "american_cockerpoo",
  american_english_coonhound = "american_english_coonhound",
  american_eskimo_dog = "american_eskimo_dog",
  american_foxhound = "american_foxhound",
  american_hairless_terrier = "american_hairless_terrier",
  american_mastiff = "american_mastiff",
  american_pit_bull_terrier = "american_pit_bull_terrier",
  american_shepherd = "american_shepherd",
  american_staffordshire_terrier = "american_staffordshire_terrier",
  american_staghound = "american_staghound",
  american_water_spaniel = "american_water_spaniel",
  american_white_shepherd = "american_white_shepherd",
  american_xl_bully = "american_xl_bully",
  anatolian_karabash = "anatolian_karabash",
  anatolian_shepherd_dog = "anatolian_shepherd_dog",
  andalusian_podenco = "andalusian_podenco",
  appenzeller_sennenhund = "appenzeller_sennenhund",
  argentine_dogo = "argentine_dogo",
  ariege_pointer = "ariege_pointer",
  ariegeois = "ariegeois",
  aussiedoodle_australian_shepherd_poodle_mix = "aussiedoodle_australian_shepherd_poodle_mix",
  australian_bulldog = "australian_bulldog",
  australian_cattle_dog = "australian_cattle_dog",
  australian_kelpie = "australian_kelpie",
  australian_labradoodle = "australian_labradoodle",
  australian_shepherd = "australian_shepherd",
  australian_silky_terrier = "australian_silky_terrier",
  australian_stumpy_tail_cattle_dog = "australian_stumpy_tail_cattle_dog",
  australian_terrier = "australian_terrier",
  austrian_black_and_tan_hound = "austrian_black_and_tan_hound",
  austrian_pinscher = "austrian_pinscher",
  azawakh = "azawakh",
  barbet = "barbet",
  basenji = "basenji",
  bashar = "bashar",
  basset_bleu_de_gascogne = "basset_bleu_de_gascogne",
  basset_fauve_de_bretagne = "basset_fauve_de_bretagne",
  basset_hound = "basset_hound",
  bassetoodle_basset_hound_poodle_mix = "bassetoodle_basset_hound_poodle_mix",
  bassugg = "bassugg",
  bavarian_mountain_hound = "bavarian_mountain_hound",
  beagle = "beagle",
  beagle_harrier = "beagle_harrier",
  beaglier = "beaglier",
  bearded_collie = "bearded_collie",
  beardiecockerpoo_bearded_collie_cockerpoo_mix = "beardiecockerpoo_bearded_collie_cockerpoo_mix",
  beauceron = "beauceron",
  bedlington_terrier = "bedlington_terrier",
  belgian_malinois = "belgian_malinois",
  belgian_sheepdog = "belgian_sheepdog",
  belgian_shepherd_dog = "belgian_shepherd_dog",
  belgian_shepherd_laekenois = "belgian_shepherd_laekenois",
  belgian_shepherd_malinois = "belgian_shepherd_malinois",
  belgian_shepherd_tervuren = "belgian_shepherd_tervuren",
  belgian_tervuren = "belgian_tervuren",
  bergamasco = "bergamasco",
  berger_picard = "berger_picard",
  bernedoodle_bernese_mountain_dog_poodle_mix = "bernedoodle_bernese_mountain_dog_poodle_mix",
  bernese_mountain_dog = "bernese_mountain_dog",
  bichon_frise = "bichon_frise",
  bichpoo_bichon_frise_poodle_mix = "bichpoo_bichon_frise_poodle_mix",
  biewer_terrier = "biewer_terrier",
  black_and_tan_coonhound = "black_and_tan_coonhound",
  black_norwegian_elkhound = "black_norwegian_elkhound",
  black_russian_terrier = "black_russian_terrier",
  blackmouth_cur = "blackmouth_cur",
  bloodhound = "bloodhound",
  blue_lacy = "blue_lacy",
  blue_merle_collie = "blue_merle_collie",
  blue_picardy_spaniel = "blue_picardy_spaniel",
  bluetick_coonhound = "bluetick_coonhound",
  bocker_beagle_cocker_spaniel_mix = "bocker_beagle_cocker_spaniel_mix",
  boerboel = "boerboel",
  bohemian_shepherd = "bohemian_shepherd",
  bolognese = "bolognese",
  bolonka = "bolonka",
  bolonoodle_bolognese_poodle_mix = "bolonoodle_bolognese_poodle_mix",
  boomer = "boomer",
  borador = "borador",
  border_collie = "border_collie",
  border_terrier = "border_terrier",
  bordernese_bernese_mountain_dog_x_border_collie = "bordernese_bernese_mountain_dog_x_border_collie",
  bordoodle_border_collie_poodle_mix = "bordoodle_border_collie_poodle_mix",
  borzoi = "borzoi",
  bossipoo_boston_terrier_poodle_mix = "bossipoo_boston_terrier_poodle_mix",
  boston_terrier = "boston_terrier",
  bouvier_de_ardennes = "bouvier_de_ardennes",
  bouvier_des_flandres = "bouvier_des_flandres",
  boxador = "boxador",
  boxer = "boxer",
  boxerdoodle_boxer_poodle_mix = "boxerdoodle_boxer_poodle_mix",
  boykin_spaniel = "boykin_spaniel",
  bracco_italiano = "bracco_italiano",
  braque_dauvergne = "braque_dauvergne",
  braque_du_bourbonnais = "braque_du_bourbonnais",
  brazilian_terrier = "brazilian_terrier",
  briard = "briard",
  briquet_griffon_vendeen = "briquet_griffon_vendeen",
  brittany = "brittany",
  broodle_griffon_brussels_griffon_poodle = "broodle_griffon_brussels_griffon_poodle",
  brussels_griffon = "brussels_griffon",
  bukovina_sheepdog = "bukovina_sheepdog",
  bulgarian_shepherd_dog = "bulgarian_shepherd_dog",
  bull_greyhound = "bull_greyhound",
  bull_terrier = "bull_terrier",
  bulldog = "bulldog",
  bullmastiff = "bullmastiff",
  bullpei = "bullpei",
  cadoodle_collie_x_poodle_mix = "cadoodle_collie_x_poodle_mix",
  cairn_terrier = "cairn_terrier",
  cairnoodle_cairn_terrier_poodle_mix = "cairnoodle_cairn_terrier_poodle_mix",
  canaan_dog = "canaan_dog",
  canadian_eskimo_dog = "canadian_eskimo_dog",
  cane_corso = "cane_corso",
  cao_da_serra_de_aires = "cao_da_serra_de_aires",
  caravan_hound = "caravan_hound",
  cardigan_welsh_corgi = "cardigan_welsh_corgi",
  carolina_dog = "carolina_dog",
  carpathian_sheepdog = "carpathian_sheepdog",
  catahoula_bulldog = "catahoula_bulldog",
  catahoula_cur = "catahoula_cur",
  catahoula_leopard_dog = "catahoula_leopard_dog",
  catalan_sheepdog = "catalan_sheepdog",
  caucasian_ovtcharka = "caucasian_ovtcharka",
  cavachon = "cavachon",
  cavalier_king_charles_spaniel = "cavalier_king_charles_spaniel",
  cavapoo_cavalier_king_charles_spaniel_poodle_mix = "cavapoo_cavalier_king_charles_spaniel_poodle_mix",
  cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix = "cavapoochon_cavalier_king_charles_bichon_frise_poodle_mix",
  central_asian_shepherd_dog = "central_asian_shepherd_dog",
  cesky_fousek = "cesky_fousek",
  cesky_terrier = "cesky_terrier",
  chart_polski = "chart_polski",
  cheagle = "cheagle",
  chesapeake_bay_retriever = "chesapeake_bay_retriever",
  chihuahua = "chihuahua",
  chinese_crested = "chinese_crested",
  chinese_crestepoo_chinese_crested_poodle_mix = "chinese_crestepoo_chinese_crested_poodle_mix",
  chinese_sharpei = "chinese_sharpei",
  chinook = "chinook",
  chipoo_chihuahua_poodle_mix = "chipoo_chihuahua_poodle_mix",
  chishi = "chishi",
  chiweenie = "chiweenie",
  chorkie = "chorkie",
  chow_chow = "chow_chow",
  chug_chihuahua_pug_mix = "chug_chihuahua_pug_mix",
  cirneco_dell_etna = "cirneco_dell_etna",
  clumber_spaniel = "clumber_spaniel",
  clumberdoodle = "clumberdoodle",
  cockador = "cockador",
  cockapoo_cocker_spaniel_poodle_mix = "cockapoo_cocker_spaniel_poodle_mix",
  cocker_spaniel = "cocker_spaniel",
  cockerchon_cocker_bichon_frise_cross = "cockerchon_cocker_bichon_frise_cross",
  cockerpei_cocker_spaniel_sharpei_mix = "cockerpei_cocker_spaniel_sharpei_mix",
  collie = "collie",
  collie_x_retriever = "collie_x_retriever",
  corgipoo_corgi_poodle_mix = "corgipoo_corgi_poodle_mix",
  coton_de_tulear = "coton_de_tulear",
  croatian_sheepdog = "croatian_sheepdog",
  crossbreed = "crossbreed",
  curlycoated_retriever = "curlycoated_retriever",
  czechoslovakian_shepherd = "czechoslovakian_shepherd",
  czechoslovakian_wolfdog = "czechoslovakian_wolfdog",
  dachshund = "dachshund",
  daisy_dog_bichon_poodle_shih_tzu_mix = "daisy_dog_bichon_poodle_shih_tzu_mix",
  dalmadoodle_dalmatian_poodle_mix = "dalmadoodle_dalmatian_poodle_mix",
  dalmatian = "dalmatian",
  dandie_dinmont_terrier = "dandie_dinmont_terrier",
  danishswedish_farmdog = "danishswedish_farmdog",
  deerhound = "deerhound",
  deutsche_bracke = "deutsche_bracke",
  doberman_pinscher = "doberman_pinscher",
  dobermann = "dobermann",
  dogue_de_bordeaux = "dogue_de_bordeaux",
  doodleman_pinscher_doberman_standard_poodle_mix = "doodleman_pinscher_doberman_standard_poodle_mix",
  double_doodle_goldendoodle_labradoodle_mix = "double_doodle_goldendoodle_labradoodle_mix",
  doxiepoo_dachshund_poodle_mix = "doxiepoo_dachshund_poodle_mix",
  drentse_patrijshond = "drentse_patrijshond",
  drever = "drever",
  dunker = "dunker",
  dutch_shepherd = "dutch_shepherd",
  dutch_smoushond = "dutch_smoushond",
  east_siberian_laika = "east_siberian_laika",
  easteuropean_shepherd = "easteuropean_shepherd",
  english_boodle_english_bulldog_poodle_mix = "english_boodle_english_bulldog_poodle_mix",
  english_bully_english_bull_terrier_staffordshire_bull_terrier = "english_bully_english_bull_terrier_staffordshire_bull_terrier",
  english_cocker_spaniel = "english_cocker_spaniel",
  english_coonhound = "english_coonhound",
  english_foxhound = "english_foxhound",
  english_mastiff = "english_mastiff",
  english_pointer = "english_pointer",
  english_setter = "english_setter",
  english_shepherd = "english_shepherd",
  english_springer_spaniel = "english_springer_spaniel",
  english_toy_spaniel = "english_toy_spaniel",
  english_toy_terrier = "english_toy_terrier",
  entlebucher_mountain_dog = "entlebucher_mountain_dog",
  eskapoo_american_eskimo_dog_poodle_mix = "eskapoo_american_eskimo_dog_poodle_mix",
  eskimo_dog = "eskimo_dog",
  estrela_mountain_dog = "estrela_mountain_dog",
  eurasier = "eurasier",
  field_spaniel = "field_spaniel",
  fila_brasileiro = "fila_brasileiro",
  finnish_hound = "finnish_hound",
  finnish_lapphund = "finnish_lapphund",
  finnish_spitz = "finnish_spitz",
  flandoodle_bouvier_des_flandres_poodle_mix = "flandoodle_bouvier_des_flandres_poodle_mix",
  flatcoated_retriever = "flatcoated_retriever",
  foodle_poodle_toy_fox_terrier_mix = "foodle_poodle_toy_fox_terrier_mix",
  fox_terrier_toy = "fox_terrier_toy",
  foxhoodle_fox_hound_poodle_mix = "foxhoodle_fox_hound_poodle_mix",
  francais_blanc_et_noir = "francais_blanc_et_noir",
  french_brittany = "french_brittany",
  french_bulldog = "french_bulldog",
  french_spaniel = "french_spaniel",
  frug = "frug",
  gerberian_shepsky_german_shepherd_x_siberian_husky = "gerberian_shepsky_german_shepherd_x_siberian_husky",
  german_longhaired_pointer = "german_longhaired_pointer",
  german_pinscher = "german_pinscher",
  german_shepherd = "german_shepherd",
  german_shepherd_dog = "german_shepherd_dog",
  german_sheprador = "german_sheprador",
  german_short_haired_pointer = "german_short_haired_pointer",
  german_shorthaired_pointer = "german_shorthaired_pointer",
  german_spitz = "german_spitz",
  german_wirehaired_pointer = "german_wirehaired_pointer",
  giant_schnauzer = "giant_schnauzer",
  giant_schnoodle_giant_schnauzer_standard_poodle_mix = "giant_schnoodle_giant_schnauzer_standard_poodle_mix",
  glen_of_imaal_terrier = "glen_of_imaal_terrier",
  goldador = "goldador",
  golden_retriever = "golden_retriever",
  golden_retriever_x_newfoundland = "golden_retriever_x_newfoundland",
  goldendoodle_golden_retriever_poodle_mix = "goldendoodle_golden_retriever_poodle_mix",
  gordon_setter = "gordon_setter",
  grand_basset_griffon_vendeen = "grand_basset_griffon_vendeen",
  grand_bleu_de_gascogne = "grand_bleu_de_gascogne",
  great_dane = "great_dane",
  great_danoodle_great_dane_poodle_mix = "great_danoodle_great_dane_poodle_mix",
  great_pyrenees = "great_pyrenees",
  great_swiss_mountain_dog = "great_swiss_mountain_dog",
  greater_swiss_mountain_dog = "greater_swiss_mountain_dog",
  greek_harehound = "greek_harehound",
  greenland_dog = "greenland_dog",
  greyhound = "greyhound",
  greyhound_x_saluki = "greyhound_x_saluki",
  griffon_bleu_du_gascogne = "griffon_bleu_du_gascogne",
  griffon_bruxellois = "griffon_bruxellois",
  griffon_nivernais = "griffon_nivernais",
  groenendael = "groenendael",
  hamiltonstovare = "hamiltonstovare",
  harrier = "harrier",
  havanese = "havanese",
  hokkaido = "hokkaido",
  hovawart = "hovawart",
  hungarian_vizsla = "hungarian_vizsla",
  hush_basset_cocker_spaniel_x_basset_hound = "hush_basset_cocker_spaniel_x_basset_hound",
  husky_x_samoyed = "husky_x_samoyed",
  huskyhungarian_vizsla = "huskyhungarian_vizsla",
  ibizan_hound = "ibizan_hound",
  icelandic_sheepdog = "icelandic_sheepdog",
  imperial_shih_tzu = "imperial_shih_tzu",
  irish_doodle_irish_setter_poodle_mix = "irish_doodle_irish_setter_poodle_mix",
  irish_red_and_white_setter = "irish_red_and_white_setter",
  irish_setter = "irish_setter",
  irish_terrier = "irish_terrier",
  irish_troodle_irish_terrier_poodle_mix = "irish_troodle_irish_terrier_poodle_mix",
  irish_water_spaniel = "irish_water_spaniel",
  irish_wolfhound = "irish_wolfhound",
  italian_greyhound = "italian_greyhound",
  italian_spinone = "italian_spinone",
  jack_highland_terrier = "jack_highland_terrier",
  jack_russell_terrier = "jack_russell_terrier",
  jackabee = "jackabee",
  jackapoo_jack_russell_terrier_poodle_mix = "jackapoo_jack_russell_terrier_poodle_mix",
  jackawawa = "jackawawa",
  jackchi = "jackchi",
  jagdterrier = "jagdterrier",
  jamthund_swedish_elkhound = "jamthund_swedish_elkhound",
  japanese_akita = "japanese_akita",
  japanese_chin = "japanese_chin",
  japanese_shiba_inu = "japanese_shiba_inu",
  japanese_spitz = "japanese_spitz",
  japanese_terrier = "japanese_terrier",
  jorkie_yorkshire_terrier_jack_russell_mix = "jorkie_yorkshire_terrier_jack_russell_mix",
  jug = "jug",
  karelian_bear_dog = "karelian_bear_dog",
  keeshond = "keeshond",
  kerry_blue_terrier = "kerry_blue_terrier",
  king_charles_spaniel = "king_charles_spaniel",
  kishu = "kishu",
  kokoni = "kokoni",
  komondor = "komondor",
  kooikerhondje = "kooikerhondje",
  korean_jindo = "korean_jindo",
  korthals_griffon = "korthals_griffon",
  kromfohrlander = "kromfohrlander",
  kuvasz = "kuvasz",
  kyileo = "kyileo",
  labradoodle_labrador_retriever_poodle_mix = "labradoodle_labrador_retriever_poodle_mix",
  labrador_retriever = "labrador_retriever",
  labrador_x_spaniel_mix = "labrador_x_spaniel_mix",
  labrador_x_vizsla_mix = "labrador_x_vizsla_mix",
  lacasapoo_cockapoo_x_lhasa_apso_mix = "lacasapoo_cockapoo_x_lhasa_apso_mix",
  lagotto_romagnolo = "lagotto_romagnolo",
  lakeland_terrier = "lakeland_terrier",
  lakeland_trailhound = "lakeland_trailhound",
  lancashire_heeler = "lancashire_heeler",
  landseer = "landseer",
  lapponian_herder = "lapponian_herder",
  large_munsterlander = "large_munsterlander",
  leonberger = "leonberger",
  lhasa_apso = "lhasa_apso",
  lhasa_apso_x_shih_tzu = "lhasa_apso_x_shih_tzu",
  lhasapoo_lhasa_apso_poodle_mix = "lhasapoo_lhasa_apso_poodle_mix",
  lhatese_malteselhasa_apso = "lhatese_malteselhasa_apso",
  lowchen = "lowchen",
  lurcher = "lurcher",
  lurcher_x_spaniel = "lurcher_x_spaniel",
  malshi = "malshi",
  maltese = "maltese",
  maltichon = "maltichon",
  maltipoo_maltese_poodle_mix = "maltipoo_maltese_poodle_mix",
  manchester_terrier = "manchester_terrier",
  maremma_sheepdog = "maremma_sheepdog",
  mastador = "mastador",
  mastidoodle_mastiff_poodle_mix = "mastidoodle_mastiff_poodle_mix",
  mastiff = "mastiff",
  mcnab = "mcnab",
  miki = "miki",
  mini_american_shepherd = "mini_american_shepherd",
  miniature_aussiedoodle_miniature_australian_shepherd_miniature_poodle_mix = "miniature_aussiedoodle_miniature_australian_shepherd_miniature_poodle_mix",
  miniature_bull_terrier = "miniature_bull_terrier",
  miniature_dachshund = "miniature_dachshund",
  miniature_goldendoodle_golden_retriever_toy_or_miniature_poodle_mix = "miniature_goldendoodle_golden_retriever_toy_or_miniature_poodle_mix",
  miniature_jack_russel_terrier = "miniature_jack_russel_terrier",
  miniature_labradoodle_labrador_retriever_toy_or_miniature_poodle_mix = "miniature_labradoodle_labrador_retriever_toy_or_miniature_poodle_mix",
  miniature_pinscher = "miniature_pinscher",
  miniature_poodle = "miniature_poodle",
  miniature_schnauzer = "miniature_schnauzer",
  miniature_yorkshire_terrier = "miniature_yorkshire_terrier",
  mongrel = "mongrel",
  morkie = "morkie",
  mudi = "mudi",
  neapolitan_mastiff = "neapolitan_mastiff",
  new_guinea_singing_dog = "new_guinea_singing_dog",
  new_zealand_huntaway = "new_zealand_huntaway",
  new_zealand_huntaway_cross = "new_zealand_huntaway_cross",
  newfoundland = "newfoundland",
  newfypoo_newfoundland_poodle_mix = "newfypoo_newfoundland_poodle_mix",
  norfolk_terrier = "norfolk_terrier",
  norrbottenspets = "norrbottenspets",
  northern_inuit = "northern_inuit",
  norwegian_buhund = "norwegian_buhund",
  norwegian_elkhound = "norwegian_elkhound",
  norwegian_lundehund = "norwegian_lundehund",
  norwich_terrier = "norwich_terrier",
  nova_scotia_duck_tolling_retriever = "nova_scotia_duck_tolling_retriever",
  old_english_sheepdog = "old_english_sheepdog",
  olde_english_bulldogge = "olde_english_bulldogge",
  olde_tyme_bulldog = "olde_tyme_bulldog",
  other = "other",
  otterhound = "otterhound",
  pakistani_mastiff = "pakistani_mastiff",
  paperanian_papillonpomeranian_mix = "paperanian_papillonpomeranian_mix",
  papillion = "papillion",
  papillon = "papillon",
  papipoo_papillon_poodle_mix = "papipoo_papillon_poodle_mix",
  parson_jack_russell_terrier = "parson_jack_russell_terrier",
  parson_russell_terrier = "parson_russell_terrier",
  patterdale_terrier = "patterdale_terrier",
  pekepoo_pekingese_poodle_mix = "pekepoo_pekingese_poodle_mix",
  pekingese = "pekingese",
  pembroke_welsh_corgi = "pembroke_welsh_corgi",
  perro_de_presa_canario = "perro_de_presa_canario",
  perro_de_presa_mallorquin = "perro_de_presa_mallorquin",
  peruvian_inca_orchid = "peruvian_inca_orchid",
  petit_basset_griffon_vendeen = "petit_basset_griffon_vendeen",
  petite_goldendoodle_golden_retriever_cocker_spaniel_poodle_mix = "petite_goldendoodle_golden_retriever_cocker_spaniel_poodle_mix",
  petite_labradoodle_labrador_retriever_cocker_spaniel_poodle_mix = "petite_labradoodle_labrador_retriever_cocker_spaniel_poodle_mix",
  pharaoh_hound = "pharaoh_hound",
  picardy_spaniel = "picardy_spaniel",
  pinnypoo_miniature_pinscher_poodle_mix = "pinnypoo_miniature_pinscher_poodle_mix",
  plott = "plott",
  plott_hound = "plott_hound",
  plummer_terrier = "plummer_terrier",
  podenco_canario = "podenco_canario",
  podenco_maneto = "podenco_maneto",
  pointer = "pointer",
  polish_hunting_dog = "polish_hunting_dog",
  polish_lowland_sheepdog = "polish_lowland_sheepdog",
  polish_tatra_sheepdog = "polish_tatra_sheepdog",
  pomapoo_pomeranian_poodle_mix = "pomapoo_pomeranian_poodle_mix",
  pomchi = "pomchi",
  pomeranian = "pomeranian",
  pomsky = "pomsky",
  pontaudemer_spaniel = "pontaudemer_spaniel",
  poochin_japanese_chin_poodle_mix = "poochin_japanese_chin_poodle_mix",
  poodle = "poodle",
  poogle_beagle_poodle_mix = "poogle_beagle_poodle_mix",
  poolky_poodle_silky_terrier_mix = "poolky_poodle_silky_terrier_mix",
  pooshi_poodle_shiba_inu_mix = "pooshi_poodle_shiba_inu_mix",
  pooshih = "pooshih",
  pootalian_italian_greyhound_poodle_mix = "pootalian_italian_greyhound_poodle_mix",
  pooton_coton_de_tulear_poodle_mix = "pooton_coton_de_tulear_poodle_mix",
  poovanese_havanese_poodle_mix = "poovanese_havanese_poodle_mix",
  porkie = "porkie",
  portuguese_podengo = "portuguese_podengo",
  portuguese_podengo_pequeno = "portuguese_podengo_pequeno",
  portuguese_pointer = "portuguese_pointer",
  portuguese_water_dog = "portuguese_water_dog",
  posavac_hound = "posavac_hound",
  prazsky_krysarik = "prazsky_krysarik",
  pudelpointer = "pudelpointer",
  pug = "pug",
  pug_x_staffordshire_bull_terrier = "pug_x_staffordshire_bull_terrier",
  pugalier = "pugalier",
  pugapoo_pug_poodle_mix = "pugapoo_pug_poodle_mix",
  puggle = "puggle",
  puggle_pug_beagle_mix = "puggle_pug_beagle_mix",
  pugzu = "pugzu",
  puli = "puli",
  pumi = "pumi",
  pyredoodle_great_pyrenees_poodle_mix = "pyredoodle_great_pyrenees_poodle_mix",
  pyrenean_mastiff = "pyrenean_mastiff",
  pyrenean_mountain_dog = "pyrenean_mountain_dog",
  pyrenean_shepherd = "pyrenean_shepherd",
  rafeiro_do_alentejo = "rafeiro_do_alentejo",
  rajapalyam = "rajapalyam",
  rat_terrier = "rat_terrier",
  ratonero_bodeguero_andaluz = "ratonero_bodeguero_andaluz",
  rattle_american_rat_terrier_poodle_mix = "rattle_american_rat_terrier_poodle_mix",
  redbone_coonhound = "redbone_coonhound",
  rhodesian_ridgeback = "rhodesian_ridgeback",
  romanian_mioritic_shepherd_dog = "romanian_mioritic_shepherd_dog",
  rottle_rottweiler_standard_poodle_mix = "rottle_rottweiler_standard_poodle_mix",
  rottweiler = "rottweiler",
  rottweiler_x_american_akita = "rottweiler_x_american_akita",
  rottweiler_x_mastiff = "rottweiler_x_mastiff",
  rough_collie = "rough_collie",
  russell_terrier = "russell_terrier",
  russian_spaniel = "russian_spaniel",
  russian_toy = "russian_toy",
  saarloos_wolfdog = "saarloos_wolfdog",
  saint_berdoodle_saint_bernard_poodle_mix = "saint_berdoodle_saint_bernard_poodle_mix",
  saint_bernard = "saint_bernard",
  saluki = "saluki",
  samoyed = "samoyed",
  sapsali = "sapsali",
  sarplaninac = "sarplaninac",
  schapendoes = "schapendoes",
  schipperke = "schipperke",
  schipperpoo_poodle_schipperke_mix = "schipperpoo_poodle_schipperke_mix",
  schnauzer = "schnauzer",
  schnoodle = "schnoodle",
  schnoodle_schnauzer_poodle_mix = "schnoodle_schnauzer_poodle_mix",
  scoodle_scottish_terrier_poodle_mix = "scoodle_scottish_terrier_poodle_mix",
  scottish_deerhound = "scottish_deerhound",
  scottish_terrier = "scottish_terrier",
  sealyham_terrier = "sealyham_terrier",
  segugio_italiano = "segugio_italiano",
  seppala_siberian_sleddog = "seppala_siberian_sleddog",
  serbian_defence_dog = "serbian_defence_dog",
  serbian_hound = "serbian_hound",
  shar_pei = "shar_pei",
  sharpoo_sharpei_poodle_mix = "sharpoo_sharpei_poodle_mix",
  sheepadoodle_old_english_sheepdog_standard_poodle_mix = "sheepadoodle_old_english_sheepdog_standard_poodle_mix",
  sheltidoodle_sheltie_poodle_mix = "sheltidoodle_sheltie_poodle_mix",
  shepadoodle_german_shepherd_standard_poodle_mix = "shepadoodle_german_shepherd_standard_poodle_mix",
  shepweiler_rottweiler_germand_shepherd_mix = "shepweiler_rottweiler_germand_shepherd_mix",
  shetland_sheepdog = "shetland_sheepdog",
  shiba_inu = "shiba_inu",
  shichon = "shichon",
  shih_tzu = "shih_tzu",
  shih_tzupugchihuahua = "shih_tzupugchihuahua",
  shih_tzy_poodle = "shih_tzy_poodle",
  shihpoo = "shihpoo",
  shihpoo_shih_tzu_poodle_mix = "shihpoo_shih_tzu_poodle_mix",
  shihpoo_standard = "shihpoo_standard",
  shikoku = "shikoku",
  shiloh_shepherd_dog = "shiloh_shepherd_dog",
  shorkie = "shorkie",
  siberian_husky = "siberian_husky",
  siberian_husky_x_norwegian_elkhound = "siberian_husky_x_norwegian_elkhound",
  siberpoo_siberian_husky_poodle_mix = "siberpoo_siberian_husky_poodle_mix",
  silken_windhound = "silken_windhound",
  silky_terrier = "silky_terrier",
  skye_terrier = "skye_terrier",
  skypoo_poodle_skye_terrier_mix = "skypoo_poodle_skye_terrier_mix",
  sloughi = "sloughi",
  slovakian_rough_haired_pointer = "slovakian_rough_haired_pointer",
  smaland_hound = "smaland_hound",
  small_munsterlander = "small_munsterlander",
  smooth_fox_terrier = "smooth_fox_terrier",
  smooth_haired_chihuahua = "smooth_haired_chihuahua",
  soft_coated_wheaten_terrier = "soft_coated_wheaten_terrier",
  soft_coated_wheaton_terrier = "soft_coated_wheaton_terrier",
  south_russian_ovcharka = "south_russian_ovcharka",
  spanish_galgo = "spanish_galgo",
  spanish_mastiff = "spanish_mastiff",
  spanish_podenco = "spanish_podenco",
  spanish_water_dog = "spanish_water_dog",
  spinone_italiano = "spinone_italiano",
  sporting_lucas_terrier = "sporting_lucas_terrier",
  spreagle = "spreagle",
  springador = "springador",
  springbatt = "springbatt",
  springerdoodle_english_springer_spaniel_poodle_mix = "springerdoodle_english_springer_spaniel_poodle_mix",
  sprocker = "sprocker",
  sprollie_springer_spaniel_x_border_collie = "sprollie_springer_spaniel_x_border_collie",
  sprusky_siberian_husky_x_springer_spaniel = "sprusky_siberian_husky_x_springer_spaniel",
  st_bernard = "st_bernard",
  stabijhoun = "stabijhoun",
  staffador = "staffador",
  staffordshire_bull_terrier = "staffordshire_bull_terrier",
  staffsky = "staffsky",
  staffy_x_french_bulldogamerican_bully = "staffy_x_french_bulldogamerican_bully",
  standard_poodle = "standard_poodle",
  standard_schnauzer = "standard_schnauzer",
  standard_schnoodle_poodle_standard_schnauzer_mix = "standard_schnoodle_poodle_standard_schnauzer_mix",
  sussex_spaniel = "sussex_spaniel",
  swedish_lapphund = "swedish_lapphund",
  swedish_valhund = "swedish_valhund",
  swedish_vallhund = "swedish_vallhund",
  swiss_shepherd = "swiss_shepherd",
  tamaskan = "tamaskan",
  terripoo_australian_terrier_poodle_mix = "terripoo_australian_terrier_poodle_mix",
  thai_bangkaew = "thai_bangkaew",
  thai_ridgeback = "thai_ridgeback",
  tibetan_mastiff = "tibetan_mastiff",
  tibetan_spaniel = "tibetan_spaniel",
  tibetan_terrier = "tibetan_terrier",
  tornjak = "tornjak",
  tosa_inu = "tosa_inu",
  toy_fox_terrier = "toy_fox_terrier",
  toy_poodle = "toy_poodle",
  transylvanian_hound = "transylvanian_hound",
  treeing_walker_coonhound = "treeing_walker_coonhound",
  ttoodle_poodle_tibetan_terrier_mix = "ttoodle_poodle_tibetan_terrier_mix",
  turkish_kangal_dog = "turkish_kangal_dog",
  turkish_kangol = "turkish_kangol",
  utonagan = "utonagan",
  valley_bulldog = "valley_bulldog",
  victorian_bulldog = "victorian_bulldog",
  vizsla = "vizsla",
  volpino_italiano = "volpino_italiano",
  weechon_westie_bischon_frise_mix = "weechon_westie_bischon_frise_mix",
  weimaraner = "weimaraner",
  weimardoodle_weimaraner_poodle_mix = "weimardoodle_weimaraner_poodle_mix",
  welsh_corgi = "welsh_corgi",
  welsh_sheepdog = "welsh_sheepdog",
  welsh_springer_spaniel = "welsh_springer_spaniel",
  welsh_terrier = "welsh_terrier",
  west_highland_schnauzer = "west_highland_schnauzer",
  west_highland_white_terrier = "west_highland_white_terrier",
  west_siberian_laika = "west_siberian_laika",
  westie_x_bedlington_terrier = "westie_x_bedlington_terrier",
  westiepoo_westie_poodle_mix = "westiepoo_westie_poodle_mix",
  wetterhoun = "wetterhoun",
  whippet = "whippet",
  whippet_x_italian_bloodhound = "whippet_x_italian_bloodhound",
  whippet_x_poodle = "whippet_x_poodle",
  white_swiss_shepherd_dog = "white_swiss_shepherd_dog",
  whoodle_soft_coated_wheaton_terrier_poodle_mix = "whoodle_soft_coated_wheaton_terrier_poodle_mix",
  wire_fox_terrier = "wire_fox_terrier",
  wire_haired_dachshund = "wire_haired_dachshund",
  wirehaired_hungarian_vizsla = "wirehaired_hungarian_vizsla",
  wirehaired_pointing_griffon = "wirehaired_pointing_griffon",
  wirepoo_wirehaired_fox_terrier_x_poodle_mix = "wirepoo_wirehaired_fox_terrier_x_poodle_mix",
  woodle_welsh_terrier_poodle_mix = "woodle_welsh_terrier_poodle_mix",
  working_cocker_spaniel = "working_cocker_spaniel",
  xoloitzcuintli = "xoloitzcuintli",
  yoranian = "yoranian",
  yorkipoo_yorkie_poodle_mix = "yorkipoo_yorkie_poodle_mix",
  yorkshire_terrier = "yorkshire_terrier",
  zuchon = "zuchon",
}

export enum Language {
  de_DE = "de_DE",
  en = "en",
  fr = "fr",
  nl = "nl",
  nl_BE = "nl_BE",
  pl = "pl",
  pl_PL = "pl_PL",
}

export enum MarketingPreferences {
  deals_only = "deals_only",
  full_consent = "full_consent",
  no_consent = "no_consent",
}

export enum MembershipTypes {
  boost = "boost",
  boost_ap_only = "boost_ap_only",
}

export enum MutationMessage {
  no_phone_error = "no_phone_error",
}

export enum NonCoreInvoiceStatus {
  failed = "failed",
  forgiven = "forgiven",
  free = "free",
  paid = "paid",
  pending = "pending",
  unpaid = "unpaid",
}

export enum NonPaymentReason {
  admin_gift = "admin_gift",
  churn_treatment = "churn_treatment",
  free_sample = "free_sample",
  raf_campaign_gift = "raf_campaign_gift",
  shopify_purchase = "shopify_purchase",
  surprise_gift = "surprise_gift",
}

export enum Payment {
  billing_agreement = "billing_agreement",
  credit_card = "credit_card",
  direct_debit = "direct_debit",
  merchant_initiated_payment = "merchant_initiated_payment",
  sepa_direct_debit = "sepa_direct_debit",
}

export enum PaymentMethodType {
  billing_agreement = "billing_agreement",
  credit_card = "credit_card",
  direct_debit = "direct_debit",
  merchant_initiated_payment = "merchant_initiated_payment",
  sepa_direct_debit = "sepa_direct_debit",
}

export enum PaymentMethodable {
  BillingAgreement = "BillingAgreement",
  CreditCard = "CreditCard",
  DirectDebit = "DirectDebit",
  MerchantInitiatedPayment = "MerchantInitiatedPayment",
  SepaDirectDebit = "SepaDirectDebit",
}

export enum PaymentResourceType {
  charge = "charge",
  payment_intent = "payment_intent",
}

export enum Profession {
  vet_nurse = "vet_nurse",
}

export enum Provider {
  gocardless = "gocardless",
  paypal = "paypal",
  revolut = "revolut",
  stripe = "stripe",
}

export enum Serving {
  single_serve = "single_serve",
  standard_serving = "standard_serving",
  unknown = "unknown",
}

export enum SnackingHabit {
  eats_lots_of_snacks = "eats_lots_of_snacks",
  eats_no_snacks = "eats_no_snacks",
  eats_some_snacks = "eats_some_snacks",
  unknown_snacking_habits = "unknown_snacking_habits",
}

export enum Status {
  allocated = "allocated",
  attempted_delivery = "attempted_delivery",
  cancelled = "cancelled",
  delivered = "delivered",
  exception = "exception",
  in = "in",
  manifested = "manifested",
  out = "out",
  printed = "printed",
  unallocated = "unallocated",
  unknown = "unknown",
}

export enum SubscriptionStatus {
  active = "active",
  paused = "paused",
  suspended = "suspended",
}

export enum Trait {
  clever_clogs = "clever_clogs",
  goofball = "goofball",
  shy = "shy",
  sweet = "sweet",
}

export enum TypeOfPlan {
  all = "all",
  half = "half",
  most = "most",
  some = "some",
}

export enum UnavailableObjectReason {
  coming_soon = "coming_soon",
  out_of_stock_hidden = "out_of_stock_hidden",
  out_of_stock_shown = "out_of_stock_shown",
}

export enum VariantDelivery {
  on_demand = "on_demand",
  one_off = "one_off",
  recurring = "recurring",
}

export interface ActivitiesInput {
  dogId: string;
  activities: Activity[];
}

export interface AddressInput {
  postcode: string;
  addressLineOne: string;
  recipientName: string;
  addressLineTwo?: string | null;
  city: string;
  shippingCountryId?: number | null;
  deliveryNotes?: string | null;
}

export interface AnswerInput {
  questionId: number;
  optionId: number;
  value: number;
}

export interface AvatarInput {
  dogId: string;
  avatar: any;
}

export interface CustomerIssueManagementInputInput {
  fieldId: string;
  booleanValue?: boolean | null;
  integerValue?: number | null;
  stringValue?: string | null;
  associationValue?: string | null;
  inputs?: CustomerIssueManagementInputInput[] | null;
}

export interface CustomerIssueManagementResolutionOfferingAcceptanceDetailsInput {
  id: string;
  replaceBox?: CustomerIssueManagementResolutionOfferingAcceptanceDetailsReplaceBoxInput | null;
}

export interface CustomerIssueManagementResolutionOfferingAcceptanceDetailsReplaceBoxInput {
  deliveryDate: any;
  deliveryAddress: AddressInput;
}

export interface CustomerIssueManagementResolutionOfferingRejectionDetailsInput {
  id: string;
}

export interface CustomerIssueReportEntryInputInput {
  fieldId: string;
  booleanValue?: boolean | null;
  integerValue?: number | null;
  stringValue?: string | null;
  associationValue?: string | null;
  entryFieldInputs?: CustomerIssueReportEntryInputInput[] | null;
}

export interface CustomerIssueResolutionAcceptanceReplacementBoxDetailsInput {
  deliveryDate: any;
  deliveryAddress: AddressInput;
}

export interface DogInput {
  name: string;
  breedId: string;
  gender: Gender;
  exercise: Exercise;
  dateOfBirth?: any | null;
  ageInMonthsWhenLastUpdated: number;
  weightInGrams: number;
  bodyShape: BodyShape;
  neutered: boolean;
  ageClassificationByUserWhenCreated: AgeClassificationByUserWhenCreated;
  feedingGuidelinesUsed: FeedingGuidelinesUsed;
  eaterType?: Eater | null;
  currentFood?: CurrentFood | null;
  snackingHabits: SnackingHabit;
  foodCategoryIds: string[];
  allergenIds?: string[] | null;
  healthIssueIds?: string[] | null;
  archiveReasonIds?: string[] | null;
}

export interface DogUpdateInput {
  id: string;
  exercise?: Exercise | null;
  weightInGrams?: number | null;
  bodyShape?: BodyShape | null;
  neutered?: boolean | null;
  gender?: Gender | null;
  name?: string | null;
  snackingHabits?: SnackingHabit | null;
  eaterType?: Eater | null;
  breedKey?: Key | null;
  healthIssueIds?: string[] | null;
  allergenIds?: string[] | null;
}

export interface FavouriteGameInput {
  dogId: string;
  favouriteGame: FavouriteGame;
}

export interface MealInput {
  beef?: number | null;
  chicken?: number | null;
  turkey?: number | null;
  lamb?: number | null;
  whiteFish?: number | null;
  pork?: number | null;
  duckAndChicken?: number | null;
  venisonWithDuck?: number | null;
  game?: number | null;
  veggie?: number | null;
  plant?: number | null;
  salmon?: number | null;
  chickenGrain?: number | null;
  porkGrain?: number | null;
  plBeef?: number | null;
  plChicken?: number | null;
  plLamb?: number | null;
  plTurkey?: number | null;
  plSalmon?: number | null;
  plPork?: number | null;
  plDuckAndChicken?: number | null;
  plGame?: number | null;
  mlBeef?: number | null;
  mlChicken?: number | null;
  mlDuckAndChicken?: number | null;
  mlLamb?: number | null;
  mlPork?: number | null;
  mlSalmon?: number | null;
}

export interface PaymentMethodInput {
  paymentMethodId: string;
  active: boolean;
  paymentMethodType: InputPaymentMethod;
  paymentProvider: Provider;
}

export interface PendingSubscriptionInput {
  flavourIds?: string[] | null;
  planId: string;
  productVariantIds?: string[] | null;
  mealSelectionType?: string | null;
}

export interface PrivacyPreferencesInput {
  consentedToMarketing?: boolean | null;
  marketingPreferences?: MarketingPreferences | null;
}

export interface ProductInput {
  productId?: string | null;
  quantity?: number | null;
  productVariantId?: string | null;
}

export interface ProductVariantInput {
  productVariantId: string;
  quantity: number;
  deliveryType: VariantDelivery;
}

export interface SubscriptionDataInput {
  planId: string;
  targetFirstDeliveryDate: any;
  paymentMethodId?: string | null;
  paymentMethodIdentifierType: string;
  paymentProvider: Provider;
  paymentMethodType: Payment;
  mealBreakdown: MealInput;
  additionalProductIds?: string[] | null;
  gaClientId?: string | null;
  deliveryCadence: string;
  user: UserSubscriptionDataInput;
}

export interface SubscriptionInput {
  planId: string;
  targetFirstDeliveryDate: any;
  stripeToken?: string | null;
  paymentMethodId?: string | null;
  paymentProvider: Provider;
  paymentMethodType: PaymentMethodable;
  mealBreakdown: MealInput;
  paymentIntentId?: string | null;
  productVariantInputs?: ProductVariantInput[] | null;
}

export interface TraitsInput {
  dogId: string;
  traits: Trait[];
}

export interface UserDetailsInput {
  email?: string | null;
  password?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

export interface UserSubscriptionDataInput {
  shouldSupportSca: boolean;
  email?: string | null;
  firstName: string;
  lastName: string;
  password?: string | null;
  passwordConfirmation?: string | null;
  phone?: string | null;
  addressAttributes: AddressInput;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

